// odsOperations.js
import * as XLSX from 'xlsx';

const getColumnIndex = (headers, columnMappings, key) => {
  const possibleHeaders = columnMappings[key] || [key];
  return headers.findIndex(header => 
    header && typeof header === 'string' && possibleHeaders.some(possibleHeader => 
      header.toLowerCase().includes(possibleHeader.toLowerCase())
    )
  );
};

const formatRow = (rowData, index, headers, columnMappings = {}) => {
  const getValue = (key) => {
    const columnIndex = getColumnIndex(headers, columnMappings, key);
    return columnIndex !== -1 ? rowData[columnIndex] : '';
  };

  return {
    id: index + 1,
    esasNo: getValue('esasNo'),
    sanikSayisi: parseInt(getValue('sanikSayisi')) || 1,
    magdurSayisi: parseInt(getValue('magdurSayisi')) || 1,
    klasorSayisi: getValue('klasorSayisi'),
    gorus: getValue('gorus'),
    sucTuru: getValue('sucTuru'),
    eskiHakim: getValue('eskiHakim'),
    manuelTevziEdilecekHakim: getValue('manuelTevziEdilecekHakim'),
    manuelTevziHakim: getValue('manuelTevziHakim'),
  };
};

const findHeaderRowIndex = (jsonData, columnMappings) => {
  const requiredHeaders = ['esasNo', 'sanikSayisi', 'magdurSayisi', 'klasorSayisi'];
  
  for (let i = 0; i < Math.min(10, jsonData.length); i++) {
    const row = jsonData[i];
    if (row && Array.isArray(row) && row.some(cell => cell && typeof cell === 'string' && requiredHeaders.some(header => 
      (columnMappings[header] || [header]).some(possibleHeader =>
        cell.toLowerCase().includes(possibleHeader.toLowerCase())
      )
    ))) {
      return i;
    }
  }
  return -1;
};

export const readOdsFile = (file, progressCallback, columnMappings = {}) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1, defval: '' });

        const headerRowIndex = findHeaderRowIndex(jsonData, columnMappings);
        if (headerRowIndex === -1) {
          throw new Error("Geçerli başlık satırı bulunamadı. Lütfen dosyanızın gerekli sütunları içerdiğinden emin olun.");
        }

        const headers = jsonData[headerRowIndex];
        const dataRows = jsonData.slice(headerRowIndex + 1);
        const totalRows = dataRows.length;

        const requiredHeaders = ['esasNo', 'sanikSayisi', 'magdurSayisi', 'klasorSayisi'];
        const missingHeaders = requiredHeaders.filter(header => 
          getColumnIndex(headers, columnMappings, header) === -1
        );

        if (missingHeaders.length > 0) {
          const missingHeadersInfo = missingHeaders.map(header => {
            const possibleHeaders = columnMappings[header] || [header];
            return `${header} (Olası başlıklar: ${possibleHeaders.join(', ')})`;
          }).join('; ');
          throw new Error(`Zorunlu başlıklar eksik: ${missingHeadersInfo}`);
        }

        const esasNoIndex = getColumnIndex(headers, columnMappings, 'esasNo');

        const formattedData = dataRows
          .filter(row => row[esasNoIndex] && row[esasNoIndex].toString().trim() !== '')
          .map((row, index) => {
            if (progressCallback) {
              progressCallback(Math.round((index / totalRows) * 100));
            }

            return formatRow(row, index, headers, columnMappings);
          });

        resolve(formattedData);
      } catch (error) {
        reject(error);
      }
    };

    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });
};

// calculateFileScore ve applyColumnMappings fonksiyonlarını excelOperations.js'den kopyalayın
// Bu fonksiyonlar ODS dosyaları için de aynı şekilde çalışacaktır