import React, { useState, useEffect } from 'react';
import { Users, Upload, Download, Edit, Trash2, ChevronUp, ChevronDown, RefreshCw } from 'lucide-react';

export const JudgeManagement = () => {
  const [judges, setJudges] = useState([]);
  const [editingJudge, setEditingJudge] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  useEffect(() => {
    const savedJudges = localStorage.getItem('judges');
    if (savedJudges) {
      setJudges(JSON.parse(savedJudges));
    }
  }, []);

  const saveJudges = (updatedJudges) => {
    setJudges(updatedJudges);
    localStorage.setItem('judges', JSON.stringify(updatedJudges));
  };

  const addJudge = () => {
    const newJudge = {
      id: Date.now(),
      name: '',
      isAvailableForAssignment: true,
      isExperienced: false,
      assignmentRatio: '1',
      score: 0
    };
    setEditingJudge(newJudge);
  };

  const updateJudge = (updatedJudge) => {
    if (judges.find(judge => judge.id === updatedJudge.id)) {
      // Mevcut hakim güncelleniyor
      const updatedJudges = judges.map(judge => 
        judge.id === updatedJudge.id ? updatedJudge : judge
      );
      saveJudges(updatedJudges);
    } else {
      // Yeni hakim ekleniyor
      saveJudges([...judges, updatedJudge]);
    }
    setEditingJudge(null);
  };

  const deleteJudge = (id) => {
    const updatedJudges = judges.filter(judge => judge.id !== id);
    saveJudges(updatedJudges);
  };

  const exportJudges = () => {
    const dataStr = JSON.stringify(judges, null, 2);
    const dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
    const exportFileDefaultName = 'judges.json';
    const linkElement = document.createElement('a');
    linkElement.setAttribute('href', dataUri);
    linkElement.setAttribute('download', exportFileDefaultName);
    linkElement.click();
  };

  const importJudges = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const importedJudges = JSON.parse(e.target.result);
        saveJudges(importedJudges);
      } catch (error) {
        alert('Geçersiz JSON dosyası');
      }
    };
    reader.readAsText(file);
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedJudges = React.useMemo(() => {
    let sortableJudges = [...judges];
    if (sortConfig.key !== null) {
      sortableJudges.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableJudges;
  }, [judges, sortConfig]);

  const resetScores = () => {
    const updatedJudges = judges.map(judge => ({ ...judge, score: 0 }));
    saveJudges(updatedJudges);
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Hakim Yönetimi</h1>
      <div className="mb-6 flex space-x-4">
        <button onClick={addJudge} className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded flex items-center transition duration-300">
          <Users className="mr-2" size={18} />
          Hakim Ekle
        </button>
        <button onClick={exportJudges} className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded flex items-center transition duration-300">
          <Download className="mr-2" size={18} />
          Dışa Aktar
        </button>
        <label className="bg-yellow-500 hover:bg-yellow-600 text-white px-4 py-2 rounded flex items-center cursor-pointer transition duration-300">
          <Upload className="mr-2" size={18} />
          İçe Aktar
          <input type="file" className="hidden" onChange={importJudges} accept=".json" />
        </label>
        <button onClick={resetScores} className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded flex items-center transition duration-300">
          <RefreshCw className="mr-2" size={18} />
          Puanları Sıfırla
        </button>
      </div>
      <div className="overflow-x-auto shadow-md rounded-lg">
        <table className="min-w-full bg-white">
          <thead className="bg-gray-300 text-gray-700">
            <tr>
              <th className="px-4 py-3 text-left text-sm font-semibold uppercase tracking-wider">
                Sıra No
              </th>
              {['name', 'isAvailableForAssignment', 'isExperienced', 'assignmentRatio', 'score'].map((key) => (
                <th 
                  key={key}
                  className="px-4 py-3 text-left text-sm font-semibold uppercase tracking-wider cursor-pointer hover:bg-gray-300 transition duration-300"
                  onClick={() => requestSort(key)}
                >
                  <div className="flex items-center">
                    {key === 'name' ? 'HAKİM ADI' :
                     key === 'isAvailableForAssignment' ? 'TEVZİYE AÇIK' :
                     key === 'isExperienced' ? 'DENEYİM' :
                     key === 'assignmentRatio' ? 'TEVZİ ORANI' : 'PUAN'}
                    {sortConfig.key === key && (
                      sortConfig.direction === 'ascending' ? <ChevronUp size={18} /> : <ChevronDown size={18} />
                    )}
                  </div>
                </th>
              ))}
              <th className="px-4 py-3 text-left text-sm font-semibold uppercase tracking-wider">İşlemler</th>
            </tr>
          </thead>
          <tbody className="text-gray-600">
            {sortedJudges.map((judge, index) => (
              <tr key={judge.id} className={index % 2 === 0 ? 'bg-gray-200' : 'bg-white'}>
                <td className="px-4 py-3 text-sm font-medium text-gray-500">{index + 1}</td>
                <td 
                  className="px-4 py-3 text-sm font-medium cursor-pointer hover:text-blue-600 transition duration-300"
                  onClick={() => setEditingJudge(judge)}
                >
                  {judge.name}
                </td>
                <td className="px-4 py-3 text-sm">
                  <span className={`px-2 py-1 rounded-full text-xs font-medium ${judge.isAvailableForAssignment ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                    {judge.isAvailableForAssignment ? 'Açık' : 'Kapalı'}
                  </span>
                </td>
                <td className="px-4 py-3 text-sm">
                  <span className={`px-2 py-1 rounded-full text-xs font-medium ${judge.isExperienced ? 'bg-blue-100 text-blue-800' : 'bg-yellow-100 text-yellow-800'}`}>
                    {judge.isExperienced ? 'Deneyimli' : 'Yeni'}
                  </span>
                </td>
                <td className="px-4 py-3 text-sm">{judge.assignmentRatio}</td>
                <td className="px-4 py-3 text-sm">{judge.score}</td>
                <td className="px-4 py-3 text-sm">
                  <button 
                    onClick={() => setEditingJudge(judge)} 
                    className="text-blue-600 hover:text-blue-800 mr-3 transition duration-300"
                    title="Düzenle"
                  >
                    <Edit size={18} />
                  </button>
                  <button 
                    onClick={() => deleteJudge(judge.id)} 
                    className="text-red-600 hover:text-red-800 transition duration-300"
                    title="Sil"
                  >
                    <Trash2 size={18} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {editingJudge && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
          <div className="relative top-20 mx-auto p-6 border w-[480px] shadow-lg rounded-md bg-white">
            <h3 className="text-xl font-semibold text-gray-900 mb-4">
              {judges.find(judge => judge.id === editingJudge.id) ? 'Hakim Bilgilerini Düzenle' : 'Yeni Hakim Ekle'}
            </h3>
            <div className="space-y-4">
              <div>
                <label htmlFor="judgeName" className="block text-sm font-medium text-gray-700 mb-1">Hakim Adı</label>
                <input
                  id="judgeName"
                  type="text"
                  value={editingJudge.name}
                  onChange={(e) => setEditingJudge({...editingJudge, name: e.target.value})}
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Hakim adını giriniz"
                />
              </div>
              <div className="flex items-center">
                <input
                  id="isAvailable"
                  type="checkbox"
                  checked={editingJudge.isAvailableForAssignment}
                  onChange={(e) => setEditingJudge({...editingJudge, isAvailableForAssignment: e.target.checked})}
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label htmlFor="isAvailable" className="ml-2 block text-sm text-gray-700">
                  Tevziye Açık
                  <span className="text-xs text-gray-500 block">Hakimin yeni davalar için uygun olup olmadığını belirtir</span>
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="isExperienced"
                  type="checkbox"
                  checked={editingJudge.isExperienced}
                  onChange={(e) => setEditingJudge({...editingJudge, isExperienced: e.target.checked})}
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label htmlFor="isExperienced" className="ml-2 block text-sm text-gray-700">
                  Deneyimli Hakim
                  <span className="text-xs text-gray-500 block">Hakimin karmaşık davalar için yeterli deneyime sahip olduğunu belirtir</span>
                </label>
              </div>
              <div>
                <label htmlFor="assignmentRatio" className="block text-sm font-medium text-gray-700 mb-1">Tevzi Oranı</label>
                <select
                  id="assignmentRatio"
                  value={editingJudge.assignmentRatio}
                  onChange={(e) => setEditingJudge({...editingJudge, assignmentRatio: e.target.value})}
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="1">Tam</option>
                  <option value="3/4">3/4</option>
                  <option value="2/3">2/3</option>
                  <option value="1/2">1/2</option>
                  <option value="1/3">1/3</option>
                  <option value="1/4">1/4</option>
                </select>
                <p className="mt-1 text-xs text-gray-500">Hakimin iş yükü dağılımını belirtir</p>
              </div>
              <div>
                <label htmlFor="score" className="block text-sm font-medium text-gray-700 mb-1">Puan</label>
                <input
                  id="score"
                  type="number"
                  value={editingJudge.score}
                  onChange={(e) => setEditingJudge({...editingJudge, score: parseInt(e.target.value, 10)})}
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Hakim puanını giriniz"
                />
                <p className="mt-1 text-xs text-gray-500">Hakimin performans puanı</p>
              </div>
            </div>
            <div className="mt-6 flex justify-end space-x-3">
              <button
                onClick={() => setEditingJudge(null)}
                className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                İptal
              </button>
              <button
                onClick={() => updateJudge(editingJudge)}
                className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Kaydet
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};