import React, { useRef, useState, useEffect } from 'react';
import { Upload, FileSpreadsheet, Loader, Check } from 'lucide-react';

const FileUploader = ({ onFileUpload, uploadProgress, uploadedFileName }) => {
  const fileInputRef = useRef(null);
  const [uploadState, setUploadState] = useState('idle'); // 'idle', 'uploading', 'uploaded'
  const [resetTimer, setResetTimer] = useState(null);

  useEffect(() => {
    return () => {
      if (resetTimer) clearTimeout(resetTimer);
    };
  }, [resetTimer]);

  const handleFileInputChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploadState('uploading');
      try {
        await onFileUpload(file);
        setUploadState('uploaded');
        const timer = setTimeout(() => {
          setUploadState('idle');
        }, 2000);
        setResetTimer(timer);
      } catch (error) {
        console.error('Dosya yükleme hatası:', error);
        setUploadState('idle');
      }
    }
  };

  const getButtonContent = () => {
    switch (uploadState) {
      case 'uploading':
        return (
          <>
            <Loader className="mr-2 animate-spin" size={18} />
            Yükleniyor...
          </>
        );
      case 'uploaded':
        return (
          <>
            <Check className="mr-2" size={18} />
            Yüklendi
          </>
        );
      default:
        return (
          <>
            <Upload className="mr-2" size={18} />
            Excel Yükle
          </>
        );
    }
  };

  const getButtonClass = () => {
    switch (uploadState) {
      case 'uploading':
        return 'bg-yellow-500 hover:bg-yellow-600';
      case 'uploaded':
        return 'bg-green-500 hover:bg-green-600';
      default:
        return 'bg-blue-500 hover:bg-blue-600';
    }
  };

  return (
    <div>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileInputChange}
        className="hidden"
        accept=".xlsx, .xls, .ods"
      />
      <button 
        onClick={() => fileInputRef.current.click()} 
        className={`${getButtonClass()} text-white px-4 py-2 rounded flex items-center transition duration-300`}
        disabled={uploadState === 'uploading'}
      >
        {getButtonContent()}
      </button>
      {uploadedFileName && (
        <div className="mt-2 flex items-center">
          <FileSpreadsheet size={24} className="mr-2 text-green-500" />
          <span>{uploadedFileName}</span>
        </div>
      )}
      {uploadProgress > 0 && uploadProgress < 100 && (
        <div className="w-full bg-gray-200 rounded-full h-2.5 mt-2 dark:bg-gray-700">
          <div 
            className="bg-blue-600 h-2.5 rounded-full" 
            style={{width: `${uploadProgress}%`}}
          ></div>
        </div>
      )}
    </div>
  );
};

export default FileUploader;