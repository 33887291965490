import React from 'react';

const DragDropArea = ({ isDragging, onDragOver, onDragLeave, onDrop }) => {
  return (
    <div 
      className={`border-2 border-dashed p-8 mb-6 mt-4 text-center transition-colors ${
        isDragging ? 'border-blue-500 bg-blue-100' : 'border-gray-300'
      } h-40 flex items-center justify-center`}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      <p className="text-lg">Excel veya ODS dosyasını buraya sürükleyip bırakın veya tıklayarak seçin</p>
    </div>
  );
};

export default DragDropArea;