import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaFolder, FaFolderOpen, FaPlus, FaTrash, FaEye, FaFileAlt } from 'react-icons/fa';

const SavedDistributions = () => {
  const [folders, setFolders] = useState([]);
  const [activeFolder, setActiveFolder] = useState(null);
  const [newFolderName, setNewFolderName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    loadFolders();
  }, []);

  const loadFolders = () => {
    const foldersString = localStorage.getItem('distributionFolders');
    if (foldersString) {
      const loadedFolders = JSON.parse(foldersString);
      setFolders(loadedFolders);
      setActiveFolder(loadedFolders[loadedFolders.length - 1]?.id || null);
    } else {
      createDefaultFolder();
    }
  };

  const createDefaultFolder = () => {
    const currentDate = new Date();
    const monthNames = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran",
      "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"
    ];
    const folderName = `${monthNames[currentDate.getMonth()]} ${currentDate.getFullYear()}`;
    const newFolder = {
      id: Date.now(),
      name: folderName,
      distributions: []
    };
    setFolders([newFolder]);
    setActiveFolder(newFolder.id);
    localStorage.setItem('distributionFolders', JSON.stringify([newFolder]));
  };

  const handleAddFolder = () => {
    if (newFolderName.trim()) {
      const newFolder = {
        id: Date.now(),
        name: newFolderName.trim(),
        distributions: []
      };
      const updatedFolders = [...folders, newFolder];
      setFolders(updatedFolders);
      setActiveFolder(newFolder.id);
      localStorage.setItem('distributionFolders', JSON.stringify(updatedFolders));
      setNewFolderName('');
    }
  };

  const handleDeleteFolder = (folderId) => {
    if (window.confirm('Bu klasörü ve içindeki tüm dağıtımları silmek istediğinizden emin misiniz?')) {
      const updatedFolders = folders.filter(folder => folder.id !== folderId);
      setFolders(updatedFolders);
      if (activeFolder === folderId) {
        setActiveFolder(updatedFolders[updatedFolders.length - 1]?.id || null);
      }
      localStorage.setItem('distributionFolders', JSON.stringify(updatedFolders));
    }
  };

  const handleSetActiveFolder = (folderId) => {
    setActiveFolder(folderId);
  };

  const handleDeleteDistribution = (folderId, distributionIndex) => {
    if (window.confirm('Bu dağıtımı silmek istediğinizden emin misiniz?')) {
      const updatedFolders = folders.map(folder => {
        if (folder.id === folderId) {
          const updatedDistributions = folder.distributions.filter((_, index) => index !== distributionIndex);
          return { ...folder, distributions: updatedDistributions };
        }
        return folder;
      });
      setFolders(updatedFolders);
      localStorage.setItem('distributionFolders', JSON.stringify(updatedFolders));
    }
  };

  const handleShowAllResults = (folderId) => {
    const folder = folders.find(f => f.id === folderId);
    if (folder) {
      const allFiles = folder.distributions.flatMap(dist => dist.files || []);
      const allDistributedFiles = folder.distributions.flatMap(dist => dist.distributedFiles || []);
      
      const combinedSummary = folder.distributions.reduce((acc, curr) => {
        if (curr.summaryReport) {
          acc.toplamDosyaSayisi = (acc.toplamDosyaSayisi || 0) + (curr.summaryReport.toplamDosyaSayisi || 0);
          acc.toplamDosyaPuani = (acc.toplamDosyaPuani || 0) + (curr.summaryReport.toplamDosyaPuani || 0);
          
          if (curr.summaryReport.hakimRaporlari) {
            curr.summaryReport.hakimRaporlari.forEach(hakim => {
              if (!acc.hakimRaporlari) acc.hakimRaporlari = [];
              const existingHakim = acc.hakimRaporlari.find(h => h.hakimAdi === hakim.hakimAdi);
              if (existingHakim) {
                existingHakim.dosyaSayisi += hakim.dosyaSayisi;
                existingHakim.toplamPuan += hakim.toplamPuan;
              } else {
                acc.hakimRaporlari.push({ ...hakim });
              }
            });
          }
        }
        return acc;
      }, {});

      navigate('/file-management', { 
        state: { 
          loadAllResults: true,
          allFiles,
          allDistributedFiles,
          combinedSummary
        } 
      });
    }
  };

  const handleViewDistribution = (folderId, distributionIndex) => {
    navigate('/file-management', { 
      state: { 
        loadDistribution: true,
        folderId,
        distributionIndex
      } 
    });
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-3xl font-bold mb-6 text-gray-800">Kayıtlı Tevziler</h1>
      
      <div className="mb-6 bg-white p-4 rounded-lg shadow">
        <h2 className="text-xl font-semibold mb-2 text-gray-700">Yeni Klasör Ekle</h2>
        <div className="flex">
          <input
            type="text"
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
            className="flex-grow mr-2 p-2 border rounded"
            placeholder="Klasör adı"
          />
          <button
            onClick={handleAddFolder}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded flex items-center"
          >
            <FaPlus className="mr-2" /> Ekle
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {folders.map(folder => (
          <div key={folder.id} className={`bg-white rounded-lg shadow-md p-4 ${activeFolder === folder.id ? 'border-2 border-blue-500' : ''}`}>
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold text-gray-800 flex items-center">
                {activeFolder === folder.id ? <FaFolderOpen className="mr-2 text-blue-500" /> : <FaFolder className="mr-2 text-gray-500" />}
                {folder.name}
              </h3>
              <div>
                <button
                  onClick={() => handleSetActiveFolder(folder.id)}
                  className={`mr-2 px-3 py-1 rounded ${activeFolder === folder.id ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'} hover:bg-blue-600 hover:text-white`}
                >
                  {activeFolder === folder.id ? 'Aktif' : 'Aktif Yap'}
                </button>
                <button
                  onClick={() => handleDeleteFolder(folder.id)}
                  className="text-red-500 hover:text-red-700"
                >
                  <FaTrash />
                </button>
              </div>
            </div>
            <button 
              onClick={() => handleShowAllResults(folder.id)}
              className="mb-4 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded w-full flex items-center justify-center"
            >
              <FaEye className="mr-2" /> Tüm Sonuçları Göster
            </button>
            <ul className="space-y-2">
              {folder.distributions.map((distribution, index) => (
                <li 
                  key={index}
                  className="bg-gray-50 rounded p-2 flex justify-between items-center"
                >
                  <button
                    onClick={() => handleViewDistribution(folder.id, index)}
                    className="text-blue-500 hover:text-blue-700 flex items-center"
                  >
                    <FaFileAlt className="mr-2" />
                    <span className="text-sm text-gray-700">{distribution.fileName || `Dağıtım #${index + 1}`}</span>
                  </button>
                  <button
                    onClick={() => handleDeleteDistribution(folder.id, index)}
                    className="text-red-500 hover:text-red-700"
                  >
                    <FaTrash />
                  </button>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SavedDistributions;