export const defaultColumnMappings = {
    esasNo: ['Esas No', 'Esas Numarası'],
    sanikSayisi: ['Sanık Sayısı', 'Sanık S.', 'Saniklar'],
    magdurSayisi: ['Mağdur Sayısı', 'Mağdur S.', 'Magdurlar'],
    klasorSayisi: ['Klasör Sayısı', 'Klasör', 'Dosya Sayısı'],
    gorus: ['T. Görüşü', 'Görüş', 'Talep'],
    eskiHakim: ['Hakim İsimleri', 'Eski Hakim', 'Önceki Hakim'],
    sucTuru: ['SUÇ TÜRÜ', 'Suç', 'Suç Tipi'],
    manuelTevziEdilecekHakim: ['Manuel Tevzi Edilecek Hakim', 'Manuel Tevzi'],
    manuelTevziHakim: ['Manuel Tevzi Hakim', 'Manuel Atanan Hakim']
  };
  
  export const defaultSettings = {
    columnMappings: defaultColumnMappings
  };

  export const defaultCalculationSettings = {
    // Hesaplama Ayarları
    dosyaPuani: 2,
    sanikPuani: 1,
    magdurPuani: 1,
    kalinDosyaPuani: 3,
    klasorPuani: 4,
    // Dağıtım Ayarları
    hakimKendiDosyasiCarpani: 0.5,
    maxSanikSayisi: 10,
    maxMagdurSayisi: 10,
    maxPuan: 50
  };