import React, { useMemo, useState } from 'react';
import { Check, X, ChevronUp, ChevronDown } from 'lucide-react';

const MainTable = ({ files, isAssignedToFormerJudge, isAssignedToManualJudge }) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  const columns = useMemo(() => [
    { key: 'index', header: 'Sıra No', width: 'w-16', sortable: true },
    { key: 'esasNo', header: 'Esas No', width: 'w-24', sortable: true },
    { key: 'sanikSayisi', header: 'Sanık S.', width: 'w-16', sortable: true },
    { key: 'magdurSayisi', header: 'Mağdur S.', width: 'w-16', sortable: true },
    { key: 'klasorSayisi', header: 'Klasör', width: 'w-16', sortable: true },
    { key: 'gorus', header: 'T. Görüşü', width: 'w-20', sortable: true },
    { key: 'sucTuru', header: 'SUÇ TÜRÜ', width: 'w-32', sortable: true },
    { key: 'eskiHakim', header: 'Eski Hakim', width: 'w-28', sortable: true },
    { key: 'eskiHakimeTevzi', header: 'Eski Hakimine Tevzi Edildi mi?', width: 'w-28', sortable: false },
    { key: 'manuelTevziEdilecekHakim', header: 'Manuel Tevzi Edilecek Hakim', width: 'w-28', sortable: true },
    { key: 'manuelTevzi', header: 'Manuel Tevzi', width: 'w-24', sortable: false },
    { key: 'dosyaPuani', header: 'Dosya Puanı', width: 'w-24', sortable: true },
    { key: 'alinanPuan', header: 'Dosyadan Alınan Puan', width: 'w-28', sortable: true },
    { key: 'atananHakim', header: 'Tevzi Edilen Hakim', width: 'w-28', sortable: true },
  ], []);

  const visibleColumns = useMemo(() => {
    return columns.filter(column => {
      if (column.key === 'index') return true;
      return files.some(file => {
        const value = file[column.key];
        return value !== null && value !== undefined && value !== '';
      });
    });
  }, [columns, files]);

  const sortedFiles = useMemo(() => {
    let sortableFiles = [...files];
    if (sortConfig.key !== null) {
      sortableFiles.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableFiles;
  }, [files, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const renderCell = (file, column, index) => {
    switch (column.key) {
      case 'index':
        return index + 1;
      case 'eskiHakimeTevzi':
        return file.eskiHakim ? (
          isAssignedToFormerJudge(file) ? (
            <div className="flex items-center justify-center w-6 h-6 bg-green-500 rounded-full">
              <Check size={16} color="white" />
            </div>
          ) : (
            <div className="flex items-center justify-center w-6 h-6 bg-red-500 rounded-full">
              <X size={16} color="white" />
            </div>
          )
        ) : '-';
      case 'manuelTevzi':
        return file.manuelTevziEdilecekHakim ? (
          isAssignedToManualJudge(file) ? (
            <div className="flex items-center justify-center w-6 h-6 bg-green-500 rounded-full">
              <Check size={16} color="white" />
            </div>
          ) : (
            <div className="flex items-center justify-center w-6 h-6 bg-red-500 rounded-full">
              <X size={16} color="white" />
            </div>
          )
        ) : '-';
      default:
        return file[column.key] || '-';
    }
  };

  return (
    <div className="overflow-x-auto w-full">
      <table className="min-w-max w-full bg-white text-sm">
        <thead className="bg-gray-400">
          <tr>
            {visibleColumns.map((column) => (
              <th 
                key={column.key} 
                className={`px-2 py-2 text-left ${column.width} break-words ${column.sortable ? 'cursor-pointer hover:bg-gray-500' : ''}`}
                onClick={() => column.sortable && requestSort(column.key)}
              >
                <div className="flex items-center">
                  {column.header}
                  {sortConfig.key === column.key && (
                    sortConfig.direction === 'ascending' ? <ChevronUp size={16} /> : <ChevronDown size={16} />
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedFiles.map((file, index) => (
            <tr 
              key={file.id} 
              className={`
                ${index % 2 === 0 ? 'bg-gray-200' : 'bg-white'}
                hover:bg-blue-100 transition-colors duration-150 ease-in-out
              `}
            >
              {visibleColumns.map((column) => (
                <td key={column.key} className="px-2 py-2">
                  {renderCell(file, column, index)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MainTable;