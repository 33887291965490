import React, { useState, useEffect } from 'react';
import { defaultCalculationSettings } from '../utils/defaultSettings';

export const CalculationSettingsPage = () => {
  const [settings, setSettings] = useState(defaultCalculationSettings);

  useEffect(() => {
    const savedSettings = localStorage.getItem('calculationSettings');
    if (savedSettings) {
      setSettings(JSON.parse(savedSettings));
    } else {
      // İlk kullanımda varsayılan ayarları localStorage'a kaydet
      localStorage.setItem('calculationSettings', JSON.stringify(defaultCalculationSettings));
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedSettings = { ...settings, [name]: parseFloat(value) };
    setSettings(updatedSettings);
    localStorage.setItem('calculationSettings', JSON.stringify(updatedSettings));
  };

  return (
    <div className="p-6 max-w-4xl mx-auto bg-white rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-8 text-center text-gray-800">Ayarlar</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Hesaplama Ayarları */}
        <div className="bg-gray-100 p-6 rounded-lg">
          <h2 className="text-2xl font-semibold mb-6 text-gray-700">Hesaplama Ayarları</h2>
          <div className="space-y-4">
           
            <SettingInput
              label="Sanık Puanı"
              name="sanikPuani"
              value={settings.sanikPuani}
              onChange={handleChange}
            />
            <SettingInput
              label="Mağdur Puanı"
              name="magdurPuani"
              value={settings.magdurPuani}
              onChange={handleChange}
            />
             <SettingInput
              label="Normal Dosya Puanı"
              name="dosyaPuani"
              value={settings.dosyaPuani}
              onChange={handleChange}
            />
            <SettingInput
              label="Kalın Dosya (KD) Puanı"
              name="kalinDosyaPuani"
              value={settings.kalinDosyaPuani}
              onChange={handleChange}
            />
            <SettingInput
              label="Klasör (K) Puanı"
              name="klasorPuani"
              value={settings.klasorPuani}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Dağıtım Ayarları */}
        <div className="bg-gray-100 p-6 rounded-lg">
          <h2 className="text-2xl font-semibold mb-6 text-gray-700">Dağıtım Ayarları</h2>
          <div className="space-y-4">
            <SettingInput
              label="Hakim Kendi Dosyası Çarpanı"
              name="hakimKendiDosyasiCarpani"
              value={settings.hakimKendiDosyasiCarpani}
              onChange={handleChange}
            />
            
            {/* Yeni Hakimler için Ayarlar */}
            <h3 className="text-xl font-semibold mt-6 mb-4 text-gray-700">Yeni Hakimler için Ayarlar</h3>
            <SettingInput
              label="Maksimum Sanık Sayısı"
              name="maxSanikSayisi"
              value={settings.maxSanikSayisi}
              onChange={handleChange}
            />
            <SettingInput
              label="Maksimum Mağdur Sayısı"
              name="maxMagdurSayisi"
              value={settings.maxMagdurSayisi}
              onChange={handleChange}
            />
            <SettingInput
              label="Maksimum Puan"
              name="maxPuan"
              value={settings.maxPuan}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const SettingInput = ({ label, name, value, onChange }) => (
  <div className="mb-4">
    <label className="block text-sm font-medium text-gray-700 mb-2">{label}:</label>
    <input
      type="number"
      name={name}
      value={value}
      onChange={onChange}
      className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150 ease-in-out"
    />
  </div>
);