import React, { useRef, useEffect, useState } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const DistributionResults = ({ hakimler, dagitimSonuclari }) => {
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (hakimler && dagitimSonuclari && dagitimSonuclari.hakimDetaylari) {
      const hakimAdlari = dagitimSonuclari.hakimDetaylari.map(hakim => hakim.hakimAdi);
      const eskiDosyaPuanlari = dagitimSonuclari.hakimDetaylari.map(hakim => hakim.eskiDosyaPuani);
      const yeniDosyaPuanlari = dagitimSonuclari.hakimDetaylari.map(hakim => hakim.yeniDosyaPuani);
      const eskiDosyaSayilari = dagitimSonuclari.hakimDetaylari.map(hakim => hakim.eskiDosyaSayisi);
      const yeniDosyaSayilari = dagitimSonuclari.hakimDetaylari.map(hakim => hakim.yeniDosyaSayisi);

      setChartData({
        labels: hakimAdlari,
        datasets: [
          {
            label: 'Eski Dosya Puanları',
            data: eskiDosyaPuanlari,
            backgroundColor: 'rgba(255, 99, 132, 0.6)', // Açık kırmızı
            stack: 'Stack 0',
            yAxisID: 'y',
          },
          {
            label: 'Yeni Dosya Puanları',
            data: yeniDosyaPuanlari,
            backgroundColor: 'rgba(220, 53, 69, 0.8)', // Koyu kırmızı
            stack: 'Stack 0',
            yAxisID: 'y',
          },
          {
            label: 'Eski Dosya Sayıları',
            data: eskiDosyaSayilari,
            backgroundColor: 'rgba(173, 216, 230, 0.8)', // Açık mavi
            stack: 'Stack 1',
            yAxisID: 'y1',
          },
          {
            label: 'Yeni Dosya Sayıları',
            data: yeniDosyaSayilari,
            backgroundColor: 'rgba(0, 123, 255, 0.8)', // Koyu mavi
            stack: 'Stack 1',
            yAxisID: 'y1',
          }
        ],
      });
    }
  }, [hakimler, dagitimSonuclari]);

  const options = {
    responsive: true,
    scales: {
      x: { stacked: true },
      y: { 
        position: 'left',
        stacked: true,
        title: {
          display: true,
          text: 'Dosya Puanı'
        }
      },
      y1: {
        position: 'right',
        stacked: true,
        title: {
          display: true,
          text: 'Dosya Sayısı'
        },
        grid: {
          drawOnChartArea: false,
        },
      }
    },
    plugins: {
      legend: { position: 'top' },
      title: { display: true, text: 'Hakim Dağıtım Sonuçları' }
    }
  };

  return (
    <div className="mt-6">
      <h2 className="text-xl font-bold mb-4">Dağıtım Sonuçları</h2>
      {chartData && <Bar ref={chartRef} options={options} data={chartData} />}
    </div>
  );
};

export default DistributionResults;
