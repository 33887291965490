import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { readExcelFile, calculateFileScore } from '../utils/excelOperations';
import { distributeDosyalar } from '../utils/distributionOperations';
import { useNavigate, useLocation } from 'react-router-dom';
import { normalizeString } from '../utils/stringUtils';
import { groupBy, shuffle } from '../utils/arrayUtils';
import FileUploader from './fileManagement/FileUploader';
import DragDropArea from './fileManagement/DragDropArea';
import ActionButtons from './fileManagement/ActionButtons';
import TabNavigation from './fileManagement/TabNavigation';
import MainTable from './fileManagement/MainTable';
import SummaryReport from './fileManagement/SummaryReport';
import JudgeDetails from './fileManagement/JudgeDetails';
import DistributionResults from './DistributionResults';
import NavigationButtons from './NavigationButtons';
import FileDetailModal from './FileDetailModal';
import { readOdsFile } from '../utils/odsOperations';

const FileManagement = () => {
  const [files, setFiles] = useState([]);
  const [calculationSettings, setCalculationSettings] = useState({
    dosyaPuani: 2,
    sanikPuani: 1,
    magdurPuani: 1,
    hakimKendiDosyasiCarpani: 0.5,
    kalinDosyaPuani: 3,
    klasorPuani: 4,
    maxSanikSayisi: 10,
    maxMagdurSayisi: 10,
    maxPuan: 50
  });
  const [judges, setJudges] = useState([]);
  const [distributedFiles, setDistributedFiles] = useState([]);
  const [summaryReport, setSummaryReport] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  const [activeTab, setActiveTab] = useState('mainTable');

  const [selectedFile, setSelectedFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [distributionResults, setDistributionResults] = useState(null);
  const [judgeDetailsSortOrder, setJudgeDetailsSortOrder] = useState('alphabetical');
  const [distributionStatus, setDistributionStatus] = useState('idle'); // 'idle', 'distributing', 'completed'

  const [folders, setFolders] = useState([]);
  const [activeFolder, setActiveFolder] = useState(null);

  const isFileUploaded = files.length > 0;
  const isDistributionCompleted = distributedFiles.length > 0;

  useEffect(() => {
    const savedSettings = localStorage.getItem('calculationSettings');
    if (savedSettings) {
      setCalculationSettings(JSON.parse(savedSettings));
    }
    const savedJudges = localStorage.getItem('judges');
    if (savedJudges) {
      setJudges(JSON.parse(savedJudges));
    }
  }, []);

  useEffect(() => {
    loadFolders();
  }, []);

  const loadFolders = () => {
    const foldersString = localStorage.getItem('distributionFolders');
    if (foldersString) {
      const loadedFolders = JSON.parse(foldersString);
      setFolders(loadedFolders);
      setActiveFolder(loadedFolders[loadedFolders.length - 1]?.id || null);
    } else {
      createDefaultFolder();
    }
  };

  const createDefaultFolder = () => {
    const currentDate = new Date();
    const monthNames = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran",
      "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"
    ];
    const folderName = `${monthNames[currentDate.getMonth()]} ${currentDate.getFullYear()}`;
    const newFolder = {
      id: Date.now(),
      name: folderName,
      distributions: []
    };
    setFolders([newFolder]);
    setActiveFolder(newFolder.id);
    localStorage.setItem('distributionFolders', JSON.stringify([newFolder]));
    return newFolder;
  };

  useEffect(() => {
    const loadDistribution = () => {
      const { state } = location;
      if (state) {
        if (state.loadDistribution) {
          loadSavedDistribution(state.folderId, state.distributionIndex);
        } else if (state.loadAllResults) {
          setFiles(state.allFiles);
          setDistributedFiles(state.allDistributedFiles);
          setSummaryReport(state.combinedSummary);
          setLastDistributionDate(new Date());
        }
      }
    };

    loadDistribution();
  }, [location]);

  const loadSavedDistribution = (folderId, distributionIndex) => {
    const foldersString = localStorage.getItem('distributionFolders');
    if (foldersString) {
      const folders = JSON.parse(foldersString);
      const folder = folders.find(f => f.id === folderId);
      if (folder && folder.distributions[distributionIndex]) {
        const distribution = folder.distributions[distributionIndex];
        setFiles(distribution.files);
        setDistributedFiles(distribution.distributedFiles);
        setSummaryReport(distribution.summaryReport);
        setLastDistributionDate(new Date(distribution.lastDistributionDate));
        setUploadedFileName(distribution.fileName);
      }
    }
  };

  const handleFileUpload = async (uploadedFile) => {
    try {
      setUploadProgress(0);
      setUploadedFileName(uploadedFile.name);
      const columnMappings = JSON.parse(localStorage.getItem('columnSettings'))?.columnMappings || {};
      
      let data;
      if (uploadedFile.name.endsWith('.ods')) {
        data = await readOdsFile(uploadedFile, (progress) => {
          setUploadProgress(progress);
        }, columnMappings);
      } else {
        data = await readExcelFile(uploadedFile, (progress) => {
          setUploadProgress(progress);
        }, columnMappings);
      }
      
      const calculatedData = data.map(file => calculateFileScore(file, calculationSettings));
      
      setFiles(calculatedData);
      setDistributedFiles([]);
      setSummaryReport(null);
      
      setActiveTab('mainTable');
      
      console.log('Yüklenen dosya içeriği:', calculatedData);
    } catch (error) {
      console.error('Dosya okuma hatası:', error.message);
      alert('Dosya yüklenirken bir hata oluştu: ' + error.message);
    } finally {
      setUploadProgress(0);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];
    if (file && (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || 
                 file.type === 'application/vnd.ms-excel' ||
                 file.type === 'application/vnd.oasis.opendocument.spreadsheet' ||
                 file.name.endsWith('.ods'))) {
      handleFileUpload(file);
    } else {
      alert('Lütfen sadece Excel veya ODS dosyası yükleyin.');
    }
  };

  const handleRecalculate = () => {
    setFiles(prevFiles => 
      prevFiles.map(file => calculateFileScore(file, calculationSettings))
    );
  };

  const [lastDistributionDate, setLastDistributionDate] = useState(null);

  const handleDistribute = async () => {
    if (files.length === 0 || judges.length === 0) {
      console.warn('Dağıtım için dosya veya hakim bulunmuyor.');
      return;
    }

    setDistributionStatus('distributing');

    // Hakim listesini karıştır
    const shuffledJudges = shuffle([...judges]);

    try {
      const results = await distributeDosyalar(files, shuffledJudges, calculationSettings);
      setDistributionResults(results);

      const { dagitilmisDosyalar, yeniHakimPuanlari, hakimHedefPuanlari, toplamDosyaPuani, ortalamaHakimPuani, toplamDosyaSayisi, dagitilmisDosyaSayisi } = results;
      
      // Dağıtılmış dosyaları güncelle ve alınan puanı hesapla
      const updatedFiles = files.map(file => {
        const dagitilmisDosya = dagitilmisDosyalar.find(d => d.id === file.id);
        if (dagitilmisDosya) {
          const alinanPuan = calculateAlinanPuan(file, dagitilmisDosya.atananHakim, calculationSettings);
          return { ...file, atananHakim: dagitilmisDosya.atananHakim, alinanPuan };
        }
        return file;
      });
      setFiles(updatedFiles);
      
      setDistributedFiles(dagitilmisDosyalar);
      setSummaryReport({
        hakimRaporlari: judges.map(judge => ({
          hakimAdi: judge.name,
          dosyaSayisi: dagitilmisDosyalar.filter(file => file.atananHakim === judge.name).length,
          toplamPuan: yeniHakimPuanlari[judge.id] || judge.score,
          hedefPuan: hakimHedefPuanlari[judge.id] || 0
        })),
        yeniHakimPuanlari,
        hakimHedefPuanlari,
        toplamDosyaPuani,
        ortalamaHakimPuani,
        toplamDosyaSayisi,
        dagitilmisDosyaSayisi
      });

      setLastDistributionDate(new Date());

      if (toplamDosyaSayisi !== dagitilmisDosyaSayisi) {
        console.warn(`Uyarı: Toplam dosya sayısı (${toplamDosyaSayisi}) ile dağıtılan dosya sayısı (${dagitilmisDosyaSayisi}) eşleşmiyor.`);
      }

      setDistributionStatus('completed');
      setTimeout(() => setDistributionStatus('idle'), 750); // 2 saniye sonra buton eski haline döner
    } catch (error) {
      console.error('Dağıtım sırasında bir hata oluştu:', error);
      setDistributionStatus('idle');
    }
  };

  const handleSaveResults = () => {
    if (!summaryReport || !lastDistributionDate) {
      alert('Önce dosya dağıtımı yapmalısınız.');
      return;
    }

    let currentActiveFolder = activeFolder;
    let currentFolders = folders;

    if (!currentActiveFolder) {
      const newFolder = createDefaultFolder();
      currentActiveFolder = newFolder.id;
      currentFolders = [newFolder];
    }

    const distributionToSave = {
      files,
      distributedFiles,
      summaryReport,
      lastDistributionDate: lastDistributionDate.toISOString(),
      fileName: uploadedFileName // Yüklenen dosyanın adını ekliyoruz
    };

    const activeFolderObject = currentFolders.find(folder => folder.id === currentActiveFolder);
    
    if (activeFolderObject) {
      const existingDistributionIndex = activeFolderObject.distributions.findIndex(
        dist => dist.fileName === uploadedFileName
      );

      if (existingDistributionIndex !== -1) {
        const confirmOverwrite = window.confirm(
          `"${uploadedFileName}" adlı bir dağıtım zaten mevcut. Üzerine yazmak istiyor musunuz?`
        );

        if (!confirmOverwrite) {
          return; // Kullanıcı üzerine yazmak istemiyorsa işlemi sonlandır
        }
      }

      const updatedFolders = currentFolders.map(folder => {
        if (folder.id === currentActiveFolder) {
          let updatedDistributions;
          if (existingDistributionIndex !== -1) {
            // Var olan dağıtımı güncelle
            updatedDistributions = [...folder.distributions];
            updatedDistributions[existingDistributionIndex] = distributionToSave;
          } else {
            // Yeni dağıtım ekle
            updatedDistributions = [...folder.distributions, distributionToSave];
          }
          return {
            ...folder,
            distributions: updatedDistributions
          };
        }
        return folder;
      });

      setFolders(updatedFolders);
      localStorage.setItem('distributionFolders', JSON.stringify(updatedFolders));

      // Hakim puanlarını güncelle
      const updatedJudges = judges.map(judge => ({
        ...judge,
        score: summaryReport.yeniHakimPuanlari[judge.id] || judge.score
      }));
      setJudges(updatedJudges);
      localStorage.setItem('judges', JSON.stringify(updatedJudges));

      alert('Sonuçlar başarıyla kaydedildi ve hakim puanları güncellendi.');
    } else {
      alert('Aktif klasör bulunamadı. Lütfen bir klasör seçin veya oluşturun.');
    }
  };

  const exportToExcel = () => {
    if (files.length === 0) {
      alert('Dışa aktarılacak dosya bulunmamaktadır.');
      return;
    }
  
    const workbook = XLSX.utils.book_new();
  
    // Ana sayfa tablosu
    const mainTableData = files.map((file, index) => ({
      'Sıra No': index + 1,
      'Esas No': file.esasNo,
      'Sanık S.': file.sanikSayisi,
      'Mağdur S.': file.magdurSayisi,
      'Klasör': file.klasorSayisi,
      'T. Görüşü': file.gorus,
      'SUÇ TÜRÜ': file.sucTuru,
      'Eski Hakim': file.eskiHakim || '-',
      'Eski Hakimine Tevzi Edildi mi?': file.eskiHakim ? 
        (isAssignedToFormerJudge(file) ? 'Evet' : 'Hayır') 
        : '-',
      'Manuel Tevzi Edilecek Hakim': file.manuelTevziEdilecekHakim || '-',
      'Manuel Tevzi': file.manuelTevziEdilecekHakim ? 
        (isAssignedToManualJudge(file) ? 'Evet' : 'Hayır') 
        : '-',
      'Dosya Puanı': file.dosyaPuani,
      'Dosyadan Alınan Puan': file.alinanPuan || '-',
      'Tevzi Edilen Hakim': file.atananHakim || '-'
    }));
    const mainSheet = XLSX.utils.json_to_sheet(mainTableData);
    XLSX.utils.book_append_sheet(workbook, mainSheet, "Ana Tablo");
  
    // Dağıtım özet raporu
    if (summaryReport) {
      const summaryData = summaryReport.hakimRaporlari.map(hakim => ({
        'Hakim Adı': hakim.hakimAdi,
        'Dosya Sayısı': hakim.dosyaSayisi,
        'Atanmış Dosyaların Toplam Puanı': hakim.atananDosyalarToplamPuani ? hakim.atananDosyalarToplamPuani.toFixed(2) : '0.00',
        'Eski Dosya Sayısı': hakim.eskiDosyaSayisi,
        'Eski Dosya Puanı': hakim.eskiDosyaPuani ? hakim.eskiDosyaPuani.toFixed(2) : '0.00'
      }));
      summaryData.push({
        'Hakim Adı': 'TOPLAM',
        'Dosya Sayısı': summaryReport.toplamDosyaSayisi,
        'Atanmış Dosyaların Toplam Puanı': summaryReport.toplamAtananDosyaPuani ? summaryReport.toplamAtananDosyaPuani.toFixed(2) : '0.00',
        'Eski Dosya Sayısı': summaryReport.hakimRaporlari.reduce((total, hakim) => total + (hakim.eskiDosyaSayisi || 0), 0),
        'Eski Dosya Puanı': summaryReport.hakimRaporlari.reduce((total, hakim) => total + (hakim.eskiDosyaPuani || 0), 0).toFixed(2)
      });
      const summarySheet = XLSX.utils.json_to_sheet(summaryData);
      XLSX.utils.book_append_sheet(workbook, summarySheet, "Dağıtım Özeti");
    }
  
    // Hakimlere göre atanmış dosyalar raporu
    if (judges && distributedFiles) {
      const judgeFilesData = judges.flatMap(judge => 
        distributedFiles.filter(file => file.atananHakim === judge.name)
          .map(file => ({
            'Hakim': judge.name,
            'Esas No': file.esasNo,
            'Suç Türü': file.sucTuru,
            'Dosya Puanı': file.dosyaPuani,
            'Eski Dosya mı?': isAssignedToFormerJudge(file) ? 'Evet' : 'Hayır'
          }))
      );
      const judgeFilesSheet = XLSX.utils.json_to_sheet(judgeFilesData);
      XLSX.utils.book_append_sheet(workbook, judgeFilesSheet, "Hakim Dosyaları");
    }
  
    // Hakim detayları raporu
    if (judges && distributedFiles) {
      const judgeDetailsData = judges.map(judge => {
        const judgeDosyalar = distributedFiles.filter(file => file.atananHakim === judge.name);
        const eskiDosyalar = judgeDosyalar.filter(dosya => isAssignedToFormerJudge(dosya));
        return {
          'Hakim Adı': judge.name,
          'Toplam Dosya Sayısı': judgeDosyalar.length,
          'Toplam Puan': judgeDosyalar.reduce((sum, dosya) => sum + (dosya.dosyaPuani || 0), 0).toFixed(2),
          'Eski Dosya Sayısı': eskiDosyalar.length,
          'Eski Dosya Puanı': eskiDosyalar.reduce((sum, dosya) => sum + (dosya.dosyaPuani || 0), 0).toFixed(2)
        };
      });
      const judgeDetailsSheet = XLSX.utils.json_to_sheet(judgeDetailsData);
      XLSX.utils.book_append_sheet(workbook, judgeDetailsSheet, "Hakim Detayları");
    }
  
    // Grafik verilerini aktarma
    if (distributionResults) {
      const graphData = [
        ['Hakim', 'Hedef Puan', 'Alınan Puan'],
        ...Object.entries(distributionResults.hakimHedefPuanlari).map(([hakimId, hedefPuan]) => {
          const hakim = judges.find(j => j.id === hakimId);
          const alinanPuan = distributionResults.yeniHakimPuanlari[hakimId] || 0;
          return [hakim ? hakim.name : hakimId, hedefPuan, alinanPuan];
        })
      ];

      const graphSheet = XLSX.utils.aoa_to_sheet(graphData);
      XLSX.utils.book_append_sheet(workbook, graphSheet, "Grafik Verileri");

      // Grafik oluşturma talimatları
      const instructionsData = [
        ['Grafik Oluşturma Talimatları:'],
        ['1. "Grafik Verileri" sayfasındaki verileri seçin'],
        ['2. "Ekle" sekmesine gidin ve "Sütun Grafik" seçeneğini tıklayın'],
        ['3. "2-B Sütun" grafiğini seçin'],
        ['4. Grafik başlığını "Hakim Puan Dağılımı" olarak değiştirin'],
        ['5. X ekseni başlığını "Hakimler" olarak ayarlayın'],
        ['6. Y ekseni başlığını "Puan" olarak ayarlayın'],
        ['7. Grafik tasarımını ve renklerini tercihinize göre özelleştirin']
      ];

      const instructionsSheet = XLSX.utils.aoa_to_sheet(instructionsData);
      XLSX.utils.book_append_sheet(workbook, instructionsSheet, "Grafik Talimatları");
    }

    XLSX.writeFile(workbook, "dosya_dagitim_raporu.xlsx");
  };

  // Alınan puanı hesaplayan yardımcı fonksiyon
  const calculateAlinanPuan = (file, atananHakim, settings) => {
    let puan = file.dosyaPuani;
    
    // Eğer dosya eski hakime atandıysa
    if (isAssignedToFormerJudge({...file, atananHakim})) {
      puan *= settings.hakimKendiDosyasiCarpani;
    }
    // Manuel tevzi durumunda tam puan alınır, burada ek bir işlem yapmaya gerek yok
    
    return puan;
  };

  const isAssignedToFormerJudge = (file) => {
    if (!file.eskiHakim || !file.atananHakim) return false;
    
    const normalizedEskiHakim = normalizeString(file.eskiHakim);
    const normalizedAtananHakim = normalizeString(file.atananHakim);
    
    return normalizedEskiHakim.includes(normalizedAtananHakim) || normalizedAtananHakim.includes(normalizedEskiHakim);
  };

  const isAssignedToManualJudge = (file) => {
    if (!file.manuelTevziEdilecekHakim || !file.atananHakim) return false;
    
    const normalizedManuelHakim = normalizeString(file.manuelTevziEdilecekHakim);
    const normalizedAtananHakim = normalizeString(file.atananHakim);
    
    return normalizedManuelHakim.includes(normalizedAtananHakim) || normalizedAtananHakim.includes(normalizedManuelHakim);
  };

  const handleFileClick = (file) => {
    setSelectedFile(file);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedFile(null);
  };
  const generateSummaryReport = () => {
    const hakimRaporlari = judges.map(hakim => {
      const atananDosyalar = distributedFiles.filter(dosya => dosya.atananHakim === hakim.name);
      const atananDosyalarToplamPuani = atananDosyalar.reduce((total, dosya) => total + (dosya.dosyaPuani || 0), 0);
      const eskiDosyalar = atananDosyalar.filter(dosya => isAssignedToFormerJudge(dosya));
      const eskiDosyaPuani = eskiDosyalar.reduce((total, dosya) => total + (dosya.dosyaPuani || 0), 0);

      return {
        hakimAdi: hakim.name,
        dosyaSayisi: atananDosyalar.length,
        atananDosyalarToplamPuani,
        eskiDosyaSayisi: eskiDosyalar.length,
        eskiDosyaPuani
      };
    });

    const toplamDosyaSayisi = distributedFiles.length;
    const toplamAtananDosyaPuani = hakimRaporlari.reduce((total, hakim) => total + hakim.atananDosyalarToplamPuani, 0);

    return {
      hakimRaporlari,
      toplamDosyaSayisi,
      toplamAtananDosyaPuani
    };
  };

  return (
    <div className="p-6 max-w-7xl mx-auto">
      <h1 className="text-3xl font-bold mb-6 text-center text-indigo-700">Dosya Yönetimi</h1>
      
      <div className="mb-8 bg-gray-50 shadow-md rounded-lg p-6">
        <h3 className="text-lg font-semibold mb-4 text-gray-800">Dosya Yükleme ve İşlemler</h3>
        <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
          <FileUploader
            onFileUpload={handleFileUpload}
            uploadProgress={uploadProgress}
            uploadedFileName={uploadedFileName}
          />
          {isFileUploaded && (
            <ActionButtons
              onDistribute={handleDistribute}
              distributionStatus={distributionStatus}
            />
          )}
        </div>
        <DragDropArea
          isDragging={isDragging}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        />
      </div>

      {isFileUploaded && (
        <div className="mb-6 bg-gray-100 shadow-md rounded-lg overflow-hidden">
          <div className="flex justify-between items-center border-b p-4">
            <TabNavigation
              activeTab={activeTab}
              onTabChange={setActiveTab}
              isDistributionCompleted={isDistributionCompleted}
            />
            {isDistributionCompleted && (
              <NavigationButtons
                onExportToExcel={exportToExcel}
                onSaveResults={handleSaveResults}
              />
            )}
          </div>

          <div className="p-4">
            {activeTab === 'mainTable' && (
              <MainTable
                files={files}
                isAssignedToFormerJudge={isAssignedToFormerJudge}
                isAssignedToManualJudge={isAssignedToManualJudge}
              />
            )}

            {isDistributionCompleted && activeTab === 'summary' && (
              <SummaryReport summaryReport={generateSummaryReport()} />
            )}

            {isDistributionCompleted && activeTab === 'judgeResults' && summaryReport && judges && judges.length > 0 && (
              <DistributionResults 
                hakimler={judges} 
                dagitimSonuclari={{
                  ...summaryReport,
                  distributedFiles: distributedFiles,
                  yeniHakimPuanlari: summaryReport.yeniHakimPuanlari,
                  hakimDetaylari: judges.map(judge => {
                    const judgeDosyalar = distributedFiles.filter(file => file.atananHakim === judge.name);
                    const eskiDosyalar = judgeDosyalar.filter(dosya => isAssignedToFormerJudge(dosya));
                    const yeniDosyalar = judgeDosyalar.filter(dosya => !isAssignedToFormerJudge(dosya));
                    return {
                      hakimAdi: judge.name,
                      toplamPuan: judgeDosyalar.reduce((sum, dosya) => sum + dosya.dosyaPuani, 0),
                      dosyaSayisi: judgeDosyalar.length,
                      eskiDosyaSayisi: eskiDosyalar.length,
                      eskiDosyaPuani: eskiDosyalar.reduce((sum, dosya) => sum + dosya.dosyaPuani, 0),
                      yeniDosyaSayisi: yeniDosyalar.length,
                      yeniDosyaPuani: yeniDosyalar.reduce((sum, dosya) => sum + dosya.dosyaPuani, 0),
                    };
                  })
                }} 
              />
            )}

            {isDistributionCompleted && activeTab === 'judgeDetails' && distributedFiles.length > 0 && (
              <JudgeDetails
                distributedFiles={distributedFiles}
                judgeDetailsSortOrder={judgeDetailsSortOrder}
                setJudgeDetailsSortOrder={setJudgeDetailsSortOrder}
                handleFileClick={handleFileClick}
                isAssignedToFormerJudge={isAssignedToFormerJudge}
              />
            )}
          </div>
        </div>
      )}

      {!isFileUploaded && (
        <div className="text-center text-gray-600 mt-4">
          Lütfen işlem yapmak için bir Excel veya ODS dosyası yükleyin.
        </div>
      )}

      <FileDetailModal
        file={selectedFile}
        isOpen={isModalOpen}
        onClose={closeModal}
      />
    </div>
  );
};

export { FileManagement };