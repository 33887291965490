import React from 'react';

const TabNavigation = ({ activeTab, onTabChange, isDistributionCompleted }) => {
  return (
    <div className="flex space-x-4">
      <button
        className={`px-4 py-2 ${activeTab === 'mainTable' ? 'text-indigo-600 border-b-2 border-indigo-600' : 'text-gray-500'}`}
        onClick={() => onTabChange('mainTable')}
      >
        Ana Tablo
      </button>
      {isDistributionCompleted && (
        <>
          <button
            className={`px-4 py-2 ${activeTab === 'judgeDetails' ? 'text-indigo-600 border-b-2 border-indigo-600' : 'text-gray-500'}`}
            onClick={() => onTabChange('judgeDetails')}
          >
            Hakim Detayları
          </button>
          <button
            className={`px-4 py-2 ${activeTab === 'judgeResults' ? 'text-indigo-600 border-b-2 border-indigo-600' : 'text-gray-500'}`}
            onClick={() => onTabChange('judgeResults')}
          >
           Grafik
          </button>
        
          <button
            className={`px-4 py-2 ${activeTab === 'summary' ? 'text-indigo-600 border-b-2 border-indigo-600' : 'text-gray-500'}`}
            onClick={() => onTabChange('summary')}
          >
            Özet
          </button>
        
        </>
      )}
    </div>
  );
};

export default TabNavigation;