import React from 'react';
import { FaFileExcel, FaSave } from 'react-icons/fa';

const NavigationButtons = ({ onExportToExcel, onSaveResults }) => {
  return (
    <div className="flex space-x-2">
      <button
        onClick={onExportToExcel}
        className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded flex items-center"
      >
        <FaFileExcel className="mr-2" />
        Excel'e Aktar
      </button>
     
      <button
        onClick={onSaveResults}
        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded flex items-center"
      >
        <FaSave className="mr-2" />
        Sonuçları Kaydet
      </button>
    </div>
  );
};

export default NavigationButtons;