import React from 'react';
import { BarChart, RefreshCw, Loader, Check } from 'lucide-react';

const ActionButtons = ({ onDistribute, onRecalculate, distributionStatus }) => {
  return (
    <div className="flex space-x-4">
      <button
        onClick={onDistribute}
        disabled={distributionStatus !== 'idle'}
        className={`
          bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded 
          inline-flex items-center transition duration-300
          ${distributionStatus !== 'idle' ? 'opacity-50 cursor-not-allowed' : ''}
        `}
      >
        {distributionStatus === 'idle' && (
          <>
            <BarChart className="mr-2" size={18} />
            Dosyaları Dağıt
          </>
        )}
        {distributionStatus === 'distributing' && (
          <>
            <Loader className="mr-2 animate-spin" size={18} />
            Dağıtılıyor...
          </>
        )}
        {distributionStatus === 'completed' && (
          <>
            <Check className="mr-2" size={18} />
            Dağıtıldı
          </>
        )}
      </button>
  
    </div>
  );
};

export default ActionButtons;