import React from 'react';
import { FileText } from 'lucide-react';

const SummaryReport = ({ summaryReport }) => {
  return (
    <div className="mt-6">
      <h2 className="text-xl font-bold mb-4 flex items-center">
        <FileText className="mr-2" />
        Dağıtım Özet Raporu
      </h2>
      <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
        <thead className="bg-gray-200 text-gray-700">
          <tr>
            <th className="py-3 px-4 text-left">Hakim Adı</th>
            <th className="py-3 px-4 text-left">Dosya Sayısı</th>
            <th className="py-3 px-4 text-left">Atanmış Dosyaların Toplam Puanı</th>
          </tr>
        </thead>
        <tbody>
          {summaryReport.hakimRaporlari.map((hakim, index) => (
            <tr key={index} className={index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}>
              <td className="py-3 px-4">{hakim.hakimAdi}</td>
              <td className="py-3 px-4">{hakim.dosyaSayisi}</td>
              <td className="py-3 px-4">{hakim.atananDosyalarToplamPuani.toFixed(2)}</td>
            </tr>
          ))}
          <tr className="bg-gray-300 font-bold">
            <td className="py-3 px-4">TOPLAM</td>
            <td className="py-3 px-4">{summaryReport.toplamDosyaSayisi}</td>
            <td className="py-3 px-4">{summaryReport.toplamAtananDosyaPuani.toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
      
      {/* Özet bilgiler için yeni bölüm */}
      <div className="mt-6 bg-blue-100 p-4 rounded-lg">
        <h3 className="text-lg font-semibold mb-2">Özet Bilgiler</h3>
        <p>Toplam Dosya Sayısı: <span className="font-bold">{summaryReport.toplamDosyaSayisi}</span></p>
        <p>Toplam Atanan Dosya Puanı: <span className="font-bold">{summaryReport.toplamAtananDosyaPuani.toFixed(2)}</span></p>
      </div>
    </div>
  );
};

export default SummaryReport;