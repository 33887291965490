import React from 'react';
import { useLocation } from 'react-router-dom';
import NavigationButtons from './NavigationButtons';

export const JudgeFiles = () => {
  const location = useLocation();
  const { judges, distributedFiles } = location.state || {};

  if (!judges || !distributedFiles) {
    return <div>Veri bulunamadı.</div>;
  }

  const totalDistributedFiles = distributedFiles.filter(file => file.atananHakim).length;
  const totalScore = distributedFiles.reduce((sum, file) => sum + file.dosyaPuani, 0);

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Hakime Göre Dosyalar</h1>
      <div className="bg-green-100 p-4 rounded-md mb-6">
        <h2 className="text-lg font-semibold mb-2">Genel Özet</h2>
        <p>Toplam Dağıtılan Dosya Sayısı: {totalDistributedFiles}</p>
        <p>Toplam Dağıtılan Puan: {totalScore.toFixed(2)}</p>
      </div>
      {judges.map(judge => {
        const judgeFiles = distributedFiles.filter(file => file.atananHakim === judge.name);
        const totalFiles = judgeFiles.length;
        const totalScore = judgeFiles.reduce((sum, file) => sum + file.dosyaPuani, 0);

        return (
          <div key={judge.id} className="mb-8">
            <h2 className="text-xl font-semibold mb-4">{judge.name}</h2>
            <table className="min-w-full bg-white mb-4">
              <thead className="bg-gray-400">
                <tr>
                  <th className="px-4 py-2 text-left">Esas No</th>
                  <th className="px-4 py-2 text-left">Dosya Puanı</th>
                </tr>
              </thead>
              <tbody>
                {judgeFiles.map((file, index) => (
                  <tr key={file.id} className={index % 2 === 0 ? 'bg-gray-200' : 'bg-white'}>
                    <td className="px-4 py-2">{file.esasNo}</td>
                    <td className="px-4 py-2">{file.dosyaPuani}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="bg-blue-100 p-4 rounded-md">
              <p className="font-semibold">Toplam Dosya Sayısı: {totalFiles}</p>
              <p className="font-semibold">Toplam Puan: {totalScore.toFixed(2)}</p>
            </div>
          </div>
        );
      })}
       <NavigationButtons
        judges={judges}
        distributedFiles={distributedFiles}
        summaryReport={JSON.parse(localStorage.getItem('distributionData'))?.summaryReport}
      />
    </div>
  );
};