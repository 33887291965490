import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BarChart, Download } from 'lucide-react';
import * as XLSX from 'xlsx';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const DistributionResultsPage = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [activeTab, setActiveTab] = useState('mainTable');

  useEffect(() => {
    // Pencere açıldığında veriyi al
    const distributionData = window.distributionData;
    if (distributionData) {
      setData(distributionData);
      setIsLoading(false);
    } else {
      console.error('Veri bulunamadı');
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return <div>Yükleniyor...</div>;
  }

  if (!data) {
    return <div>Veri bulunamadı. Lütfen dosya dağıtım işlemini tekrarlayın.</div>;
  }

  const { allFiles, fileTabs, judges, summaryReport } = data;

  console.log('Data:', { allFiles, fileTabs, judges, summaryReport }); // Debugging için

  // Veri kontrolü
  if (!allFiles || !Array.isArray(allFiles) || allFiles.length === 0) {
    console.error('Dosya verisi bulunamadı:', allFiles);
    return <div>Dosya verisi bulunamadı. Lütfen dosya dağıtım işlemini tekrarlayın.</div>;
  }

  const renderMainTable = () => {
    return (
      <table className="min-w-full bg-white">
        <thead className="bg-gray-400">
          <tr>
            <th>Esas No</th>
            <th>Sanık Sayısı</th>
            <th>Mağdur Sayısı</th>
            <th>Klasör Sayısı</th>
            <th>Dosya Puanı</th>
            <th>Atanan Hakim</th>
          </tr>
        </thead>
        <tbody>
          {allFiles.map((file, index) => (
            <tr key={file.id || index}>
              <td>{file.esasNo}</td>
              <td>{file.sanikSayisi}</td>
              <td>{file.magdurSayisi}</td>
              <td>{file.klasorSayisi}</td>
              <td>{file.dosyaPuani}</td>
              <td>{file.atananHakim || 'Atanmadı'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const renderJudgeFiles = () => {
    if (!judges || judges.length === 0) {
      return <div>Hakim bilgisi bulunamadı.</div>;
    }

    return (
      <table className="min-w-full bg-white">
        <thead className="bg-gray-400">
          <tr>
            <th>Hakim</th>
            <th>Atanan Dosyalar</th>
            <th>Toplam Puan</th>
          </tr>
        </thead>
        <tbody>
          {judges.map((judge) => (
            <tr key={judge.id}>
              <td>{judge.name}</td>
              <td>
                {judge.assignedFiles ? judge.assignedFiles.map(file => file.fileNumber).join(', ') : 'Dosya yok'}
              </td>
              <td>{judge.assignedFiles ? judge.assignedFiles.reduce((sum, file) => sum + (file.fileScore || 0), 0).toFixed(2) : '0.00'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const renderSummaryReport = () => {
    const effectiveSummaryReport = summaryReport || {
      hakimRaporlari: judges.map(judge => ({
        hakimAdi: judge.name,
        dosyaSayisi: judge.assignedFiles ? judge.assignedFiles.length : 0,
        toplamPuan: judge.assignedFiles ? judge.assignedFiles.reduce((sum, file) => sum + (file.fileScore || 0), 0) : 0
      }))
    };

    return (
      <table className="min-w-full bg-white">
        <thead className="bg-gray-400">
          <tr>
            <th>Hakim Adı</th>
            <th>Dosya Sayısı</th>
            <th>Toplam Puan</th>
          </tr>
        </thead>
        <tbody>
          {effectiveSummaryReport.hakimRaporlari.map((hakim, index) => (
            <tr key={index}>
              <td>{hakim.hakimAdi}</td>
              <td>{hakim.dosyaSayisi}</td>
              <td>{hakim.toplamPuan.toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const renderScoreDistributionChart = () => {
    if (!judges || judges.length === 0) {
      return <div>Hakim puanları bilgisi bulunamadı.</div>;
    }

    const data = {
      labels: judges.map(judge => judge.name),
      datasets: [
        {
          label: 'Toplam Puan',
          data: judges.map(judge => {
            if (judge.assignedFiles && Array.isArray(judge.assignedFiles)) {
              return judge.assignedFiles.reduce((sum, file) => sum + (file.fileScore || 0), 0);
            }
            return 0;
          }),
          backgroundColor: 'rgba(75, 192, 192, 0.6)',
        },
      ],
    };

    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Hakim Puanları Dağılımı',
        },
      },
    };

    return <Bar data={data} options={options} />;
  };

  const exportToExcel = () => {
    const workbook = XLSX.utils.book_new();

    // Ana tablo verilerini ekle
    const mainTableData = allFiles.map(file => ({
      'Dosya No': file.esasNo,
      'Sanık Sayısı': file.sanikSayisi,
      'Mağdur Sayısı': file.magdurSayisi,
      'Klasör Sayısı': file.klasorSayisi,
      'Dosya Türü': file.fileType,
      'Dosya Puanı': file.fileScore,
      'Atanan Hakim': file.assignedJudge || 'Atanmadı'
    }));
    const mainSheet = XLSX.utils.json_to_sheet(mainTableData);
    XLSX.utils.book_append_sheet(workbook, mainSheet, "Ana Tablo");

    // Hakime göre dosyalar verilerini ekle
    const judgeFilesData = judges.map(judge => ({
      'Hakim': judge.name,
      'Atanan Dosyalar': judge.assignedFiles ? judge.assignedFiles.map(file => file.fileNumber).join(', ') : 'Dosya yok',
      'Toplam Puan': judge.assignedFiles ? judge.assignedFiles.reduce((sum, file) => sum + (file.fileScore || 0), 0).toFixed(2) : '0.00'
    }));
    const judgeFilesSheet = XLSX.utils.json_to_sheet(judgeFilesData);
    XLSX.utils.book_append_sheet(workbook, judgeFilesSheet, "Hakime Göre Dosyalar");

    // Özet rapor verilerini ekle
    const summaryData = summaryReport.hakimRaporlari.map(hakim => ({
      'Hakim Adı': hakim.hakimAdi,
      'Dosya Sayısı': hakim.dosyaSayisi,
      'Toplam Puan': hakim.toplamPuan.toFixed(2)
    }));
    const summarySheet = XLSX.utils.json_to_sheet(summaryData);
    XLSX.utils.book_append_sheet(workbook, summarySheet, "Özet Rapor");

    XLSX.writeFile(workbook, "dagitim_sonuclari.xlsx");
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Dağıtım Sonuçları</h1>
      
      <div className="mb-4">
        <button
          onClick={() => setActiveTab('mainTable')}
          className={`mr-2 px-4 py-2 rounded ${activeTab === 'mainTable' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
        >
          Ana Tablo
        </button>
        <button
          onClick={() => setActiveTab('judgeFiles')}
          className={`mr-2 px-4 py-2 rounded ${activeTab === 'judgeFiles' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
        >
          Hakime Göre Dosyalar
        </button>
        <button
          onClick={() => setActiveTab('summaryReport')}
          className={`mr-2 px-4 py-2 rounded ${activeTab === 'summaryReport' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
        >
          Dağıtım Özet Raporu
        </button>
        <button
          onClick={() => setActiveTab('scoreDistribution')}
          className={`mr-2 px-4 py-2 rounded ${activeTab === 'scoreDistribution' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
        >
          Hakim Puanları Dağılımı
        </button>
      </div>

      <div className="mb-4">
        {activeTab === 'mainTable' && renderMainTable()}
        {activeTab === 'judgeFiles' && renderJudgeFiles()}
        {activeTab === 'summaryReport' && renderSummaryReport()}
        {activeTab === 'scoreDistribution' && renderScoreDistributionChart()}
      </div>

      <button
        onClick={exportToExcel}
        className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded inline-flex items-center"
      >
        <Download className="mr-2" size={18} />
        Excel'e Aktar
      </button>

      <button
        onClick={() => window.print()}
        className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded inline-flex items-center"
      >
        Yazdır / PDF Olarak Kaydet
      </button>
    </div>
  );
};

export default DistributionResultsPage;