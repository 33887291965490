import React, { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import { FiDownload, FiUpload } from 'react-icons/fi';
import { defaultSettings } from '../utils/defaultSettings.js';

const requiredHeaders = ['esasNo', 'sanikSayisi', 'magdurSayisi', 'klasorSayisi'];

export const ColumnSettingsPage = () => {
  const [settings, setSettings] = useState(defaultSettings);

  useEffect(() => {
    const savedSettings = localStorage.getItem('columnSettings');
    if (savedSettings) {
      setSettings(JSON.parse(savedSettings));
    }
  }, []);

  const handleColumnMappingChange = (key, index, value) => {
    const updatedMappings = { ...settings.columnMappings };
    if (!updatedMappings[key]) {
      updatedMappings[key] = [];
    }
    updatedMappings[key][index] = value;
    updateSettings(updatedMappings);
  };

  const addColumnMapping = (key) => {
    const updatedMappings = { ...settings.columnMappings };
    if (!updatedMappings[key]) {
      updatedMappings[key] = [];
    }
    updatedMappings[key] = [...updatedMappings[key], ''];
    updateSettings(updatedMappings);
  };

  const removeColumnMapping = (key, index) => {
    const updatedMappings = { ...settings.columnMappings };
    updatedMappings[key] = updatedMappings[key].filter((_, i) => i !== index);
    updateSettings(updatedMappings);
  };

  const updateSettings = (updatedMappings) => {
    const updatedSettings = { ...settings, columnMappings: updatedMappings };
    setSettings(updatedSettings);
    localStorage.setItem('columnSettings', JSON.stringify(updatedSettings));
  };

  const exportSettings = () => {
    const settingsJSON = JSON.stringify(settings, null, 2);
    const blob = new Blob([settingsJSON], { type: 'application/json' });
    saveAs(blob, 'column_settings.json');
  };

  const importSettings = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const importedSettings = JSON.parse(e.target.result);
          setSettings(importedSettings);
          localStorage.setItem('columnSettings', JSON.stringify(importedSettings));
        } catch (error) {
          console.error('Ayarları içe aktarırken hata oluştu:', error);
          alert('Geçersiz JSON dosyası. Lütfen doğru formatta bir dosya seçin.');
        }
      };
      reader.readAsText(file);
    }
  };

  return (
    <div className="p-6 max-w-6xl mx-auto bg-white rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-8 text-center text-gray-800">Sütun Ayarları</h1>
      
      <div className="mb-6 flex justify-between">
        <button
          onClick={exportSettings}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-300 flex items-center"
        >
          <FiUpload className="mr-2" /> Ayarları Dışa Aktar
        </button>
        <label className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300 cursor-pointer flex items-center">
          <FiDownload className="mr-2" /> Ayarları İçe Aktar
          <input
            type="file"
            accept=".json"
            onChange={importSettings}
            className="hidden"
          />
        </label>
      </div>
      
      <div className="bg-gray-100 p-6 rounded-lg">
        <h2 className="text-2xl font-semibold mb-6 text-gray-700">Sütun Eşleştirme Ayarları</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {Object.entries(settings.columnMappings || {}).map(([key, alternatives]) => (
            <div key={key} className={`bg-white p-4 rounded-lg shadow ${requiredHeaders.includes(key) ? 'border-2 border-red-500' : ''}`}>
              <h3 className="text-lg font-medium text-gray-700 mb-2">
                {key} {requiredHeaders.includes(key) && <span className="text-red-500">*</span>}
              </h3>
              {alternatives.map((alt, index) => (
                <div key={index} className="flex items-center mb-2">
                  <input
                    type="text"
                    value={alt}
                    onChange={(e) => handleColumnMappingChange(key, index, e.target.value)}
                    className="flex-grow p-2 border border-gray-300 rounded-md mr-2 text-sm"
                  />
                  <button
                    onClick={() => removeColumnMapping(key, index)}
                    className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600 transition duration-300 text-sm"
                  >
                    Sil
                  </button>
                </div>
              ))}
              <button
                onClick={() => addColumnMapping(key)}
                className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 transition duration-300 text-sm mt-2"
              >
                Ekle
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ColumnSettingsPage;