// excelOperations.js
import * as XLSX from 'xlsx';

// Varsayılan hesaplama ayarlarını tanımlayalım
const defaultCalculationSettings = {
  klasorPuani: 1,
  kalinDosyaPuani: 2,
  dosyaPuani: 0.5,
  sanikPuani: 1,
  magdurPuani: 1,
  hakimKendiDosyasiCarpani: 1.5
};

// Yeni bir fonksiyon ekleyelim
const getColumnIndex = (headers, columnMappings, key) => {
  const possibleHeaders = columnMappings[key] || [key];
  return headers.findIndex(header => 
    possibleHeaders.some(possibleHeader => 
      header.toLowerCase().includes(possibleHeader.toLowerCase())
    )
  );
};

const formatRow = (rowData, index, headers, columnMappings = {}) => {
  const getValue = (key) => {
    const columnIndex = getColumnIndex(headers, columnMappings, key);
    return columnIndex !== -1 ? rowData[columnIndex] : '';
  };

  return {
    id: index + 1,
    esasNo: getValue('esasNo'),
    sanikSayisi: parseInt(getValue('sanikSayisi')) || 1,
    magdurSayisi: parseInt(getValue('magdurSayisi')) || 1,
    klasorSayisi: getValue('klasorSayisi'),
    gorus: getValue('gorus'),
    sucTuru: getValue('sucTuru'),
    eskiHakim: getValue('eskiHakim'),
    manuelTevziEdilecekHakim: getValue('manuelTevziEdilecekHakim'),
    manuelTevziHakim: getValue('manuelTevziHakim'),
  };
};

const findHeaderRowIndex = (jsonData, columnMappings) => {
  const requiredHeaders = ['esasNo', 'sanikSayisi', 'magdurSayisi', 'klasorSayisi'];
  
  for (let i = 0; i < Math.min(5, jsonData.length); i++) {
    const row = jsonData[i];
    if (row.some(cell => cell && typeof cell === 'string' && requiredHeaders.some(header => 
      (columnMappings[header] || [header]).some(possibleHeader =>
        cell.toLowerCase().includes(possibleHeader.toLowerCase())
      )
    ))) {
      return i;
    }
  }
  return -1;
};

export const readExcelFile = (file, progressCallback, columnMappings = {}) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1, raw: true, defval: '' });

        const headerRowIndex = findHeaderRowIndex(jsonData, columnMappings);
        if (headerRowIndex === -1) {
          throw new Error("Geçerli başlık satırı bulunamadı. Lütfen dosyanızın gerekli sütunları içerdiğinden emin olun.");
        }

        const headers = jsonData[headerRowIndex];
        const totalRows = jsonData.length - headerRowIndex - 1;

        const requiredHeaders = ['esasNo', 'sanikSayisi', 'magdurSayisi', 'klasorSayisi'];
        const missingHeaders = requiredHeaders.filter(header => 
          getColumnIndex(headers, columnMappings, header) === -1
        );

        if (missingHeaders.length > 0) {
          const missingHeadersInfo = missingHeaders.map(header => {
            const possibleHeaders = columnMappings[header] || [header];
            return `${header} (Olası başlıklar: ${possibleHeaders.join(', ')})`;
          }).join('; ');
          throw new Error(`Zorunlu başlıklar eksik: ${missingHeadersInfo}`);
        }

        const formattedData = jsonData.slice(headerRowIndex + 1).map((row, index) => {
          if (progressCallback) {
            progressCallback(Math.round((index / totalRows) * 100));
          }

          return formatRow(row, index, headers, columnMappings);
        });

        resolve(formattedData);
      } catch (error) {
        reject(error);
      }
    };

    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });
};

export const calculateFileScore = (file, settings = defaultCalculationSettings) => {
  let klasorSayisi = 0;
  let kalinDosyaSayisi = 0;
  let dosyaSayisi = 0;

  const klasorRegex = /(\d+)?(KD|K|D)/g;
  let match;
  while ((match = klasorRegex.exec(file.klasorSayisi)) !== null) {
    const sayi = match[1] ? parseInt(match[1]) : 1;
    const kod = match[2];

    if (kod === 'KD') {
      kalinDosyaSayisi += sayi;
    } else if (kod === 'K') {
      klasorSayisi += sayi;
    } else if (kod === 'D') {
      dosyaSayisi += sayi;
    }
  }

  // Eğer hiçbir eşleşme bulunamazsa, varsayılan olarak normal dosya sayısını 1 olarak al
  if (klasorSayisi === 0 && kalinDosyaSayisi === 0 && dosyaSayisi === 0) {
    dosyaSayisi = 1;
  }

  const faraziKlasorSayisi = 
    klasorSayisi * settings.klasorPuani + 
    kalinDosyaSayisi * settings.kalinDosyaPuani + 
    dosyaSayisi * settings.dosyaPuani;

  const faraziDosyaSayisi = Math.ceil((file.sanikSayisi + file.magdurSayisi) / 4);
  
  const dosyaPuani = (
    file.sanikSayisi * settings.sanikPuani +
    file.magdurSayisi * settings.magdurPuani+
    faraziKlasorSayisi
    
  );

  return {
    ...file,
    faraziKlasorSayisi: faraziKlasorSayisi || 2,
    faraziDosyaSayisi: faraziDosyaSayisi || 1,
    dosyaPuani,
    hakimKendiDosyasiPuani: dosyaPuani * settings.hakimKendiDosyasiCarpani
  };
};

export const applyColumnMappings = (data, mappings, settings = defaultCalculationSettings) => {
  return data.map(row => {
    const mappedRow = {};
    for (const [key, mappedColumn] of Object.entries(mappings)) {
      if (mappedColumn && row.hasOwnProperty(mappedColumn)) {
        mappedRow[key] = row[mappedColumn];
      } else {
        mappedRow[key] = row[key]; // Eğer eşleştirme yoksa orijinal değeri koru
      }
    }
    return calculateFileScore(mappedRow, settings);
  });
};