import React from 'react';
import { useLocation } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import NavigationButtons from './NavigationButtons';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const SummaryTable = () => {
  const location = useLocation();
  const { summaryReport } = location.state || {};

  if (!summaryReport) {
    return <div>Veri bulunamadı.</div>;
  }

  const chartData = {
    labels: summaryReport.hakimRaporlari.map(hakim => hakim.hakimAdi),
    datasets: [
      {
        label: 'Mevcut Puan',
        data: summaryReport.hakimRaporlari.map(hakim => hakim.toplamPuan),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
      {
        label: 'Hedef Puan',
        data: summaryReport.hakimRaporlari.map(hakim => hakim.hedefPuan),
        backgroundColor: 'rgba(255, 159, 64, 0.6)',
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Hakim Puanları Dağılımı',
      },
    },
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Dağıtım Özet Raporu</h1>
      <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden mb-8">
        <thead className="bg-gray-200 text-gray-700">
          <tr>
            <th className="py-3 px-4 text-left">Hakim Adı</th>
            <th className="py-3 px-4 text-left">Dosya Sayısı</th>
            <th className="py-3 px-4 text-left">Toplam Puan</th>
            <th className="py-3 px-4 text-left">Hedef Puan</th>
          </tr>
        </thead>
        <tbody>
          {summaryReport.hakimRaporlari.map((hakim, index) => (
            <tr key={index} className={index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}>
              <td className="py-3 px-4">{hakim.hakimAdi}</td>
              <td className="py-3 px-4">{hakim.dosyaSayisi}</td>
              <td className="py-3 px-4">{hakim.toplamPuan.toFixed(2)}</td>
              <td className="py-3 px-4">{hakim.hedefPuan.toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Bar data={chartData} options={options} />
      <NavigationButtons
        judges={JSON.parse(localStorage.getItem('judges'))}
        distributedFiles={JSON.parse(localStorage.getItem('distributionData'))?.distributedFiles}
        summaryReport={summaryReport}
      />
    </div>
  );
};