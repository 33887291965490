import React from 'react';
import { FileText, SortAsc, Hash } from 'lucide-react';

const JudgeDetails = ({ distributedFiles, judgeDetailsSortOrder, setJudgeDetailsSortOrder, handleFileClick, isAssignedToFormerJudge }) => {
  const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
      return result;
    }, {});
  };

  const sortJudgeDetails = (detailsArray, sortOrder) => {
    return [...detailsArray].sort((a, b) => {
      if (sortOrder === 'alphabetical') {
        return a.hakimAdi.localeCompare(b.hakimAdi);
      } else if (sortOrder === 'fileCount') {
        return b.dosyalar.length - a.dosyalar.length;
      } else {
        return b.toplamPuan - a.toplamPuan;
      }
    });
  };

  const judgeDetails = Object.entries(groupBy(distributedFiles, 'atananHakim')).map(([hakimAdi, dosyalar]) => {
    const toplamPuan = dosyalar.reduce((sum, dosya) => sum + dosya.dosyaPuani, 0);
    return { hakimAdi, dosyalar, toplamPuan };
  });

  const sortedJudgeDetails = sortJudgeDetails(judgeDetails, judgeDetailsSortOrder);

  return (
    <div className="mt-6">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold flex items-center">
          <FileText className="mr-2" />
          Hakim Dosya Detayları
        </h2>
        <div className="flex space-x-2">
          <button
            onClick={() => setJudgeDetailsSortOrder('alphabetical')}
            className={`p-2 rounded ${judgeDetailsSortOrder === 'alphabetical' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            title="Alfabetik Sırala"
          >
            <SortAsc size={18} />
          </button>
          <button
            onClick={() => setJudgeDetailsSortOrder('score')}
            className={`p-2 rounded ${judgeDetailsSortOrder === 'score' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            title="Puana Göre Sırala"
          >
            <Hash size={18} />
          </button>
          <button
            onClick={() => setJudgeDetailsSortOrder('fileCount')}
            className={`p-2 rounded ${judgeDetailsSortOrder === 'fileCount' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            title="Dosya Sayısına Göre Sırala"
          >
            <FileText size={18} />
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {sortedJudgeDetails.map(({ hakimAdi, dosyalar, toplamPuan }) => (
          <div key={hakimAdi} className="bg-white shadow-lg rounded-lg overflow-hidden transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl">
            <div className="bg-gray-200 px-4 py-2 flex justify-between items-center">
              <h3 className="text-lg font-semibold">{hakimAdi}</h3>
              <div className="text-sm">
                <span className="font-medium bg-blue-500 text-white px-2 py-1 rounded mr-2">
                  TP: {toplamPuan.toFixed(2)}
                </span>
                <span className="font-medium bg-green-500 text-white px-2 py-1 rounded">
                  TD: {dosyalar.length}
                </span>
              </div>
            </div>
            <div className="p-4">
              <ul className="divide-y divide-gray-200">
                {dosyalar.map((dosya) => (
                  <li key={dosya.id} className="py-2">
                    <div className="flex justify-between items-center">
                      <div className="flex items-center">
                        <span 
                          className="text-sm font-medium text-gray-900 mr-2 cursor-pointer hover:text-blue-500"
                          onClick={() => handleFileClick(dosya)}
                        >
                          {dosya.esasNo}
                        </span>
                        {isAssignedToFormerJudge(dosya) && (
                          <span className="flex items-center justify-center bg-green-500 text-white rounded-full w-5 h-5 text-xs font-bold">
                            K
                          </span>
                        )}
                      </div>
                      <span className="text-sm text-gray-500">{dosya.dosyaPuani.toFixed(2)} puan</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default JudgeDetails;