// Önerilen Dağıtım Algoritması

import { turkishToLower } from './stringUtils';

export const distributeDosyalar = (dosyalar, hakimler, settings) => {
    const aktifHakimler = hakimler.filter(h => h.isAvailableForAssignment);
    if (aktifHakimler.length === 0) {
        return createEmptyResult(dosyalar.length);
    }

    const siraliDosyalar = [...dosyalar].sort((a, b) => b.dosyaPuani - a.dosyaPuani);
    const toplamDosyaPuani = siraliDosyalar.reduce((sum, dosya) => sum + dosya.dosyaPuani, 0);
    const toplamTevziOrani = aktifHakimler.reduce((sum, hakim) => sum + parseAssignmentRatio(hakim.assignmentRatio), 0);

    const ortalamaHakimPuani = toplamDosyaPuani / toplamTevziOrani;
    const hakimHedefPuanlari = new Map(aktifHakimler.map(h => {
        const tevziOrani = parseAssignmentRatio(h.assignmentRatio);
        return [h.id, ortalamaHakimPuani * tevziOrani];
    }));

    const hakimPuanlari = new Map(aktifHakimler.map(h => [h.id, h.score || 0]));
    const hakimDosyalari = new Map(aktifHakimler.map(h => [h.id, []]));
    const hakimDosyaSayilari = new Map(aktifHakimler.map(h => [h.id, 0]));

    // İlk aşama: Eski hakimlere dosyaları ata
    let kalanDosyalar = siraliDosyalar.filter(dosya => {
        const eskiHakimler = findEskiHakimler(dosya.eskiHakim, aktifHakimler);
        if (eskiHakimler.length > 0) {
            const eskiHakim = eskiHakimler[0]; // İlk eşleşen eski hakimi al
            assignDosyaToHakim(eskiHakim, dosya, hakimPuanlari, hakimDosyalari, hakimDosyaSayilari, settings, true);
            return false; // Bu dosyayı kalan dosyalardan çıkar
        }
        return true; // Bu dosyayı kalan dosyalarda tut
    });

    // İkinci aşama: Manuel tevzi
    kalanDosyalar = kalanDosyalar.filter(dosya => {
        if (dosya.manuelTevziEdilecekHakim) {
            const manuelHakim = findHakimByName(dosya.manuelTevziEdilecekHakim, aktifHakimler);
            if (manuelHakim) {
                assignDosyaToHakim(manuelHakim, dosya, hakimPuanlari, hakimDosyalari, hakimDosyaSayilari, settings, false);
                return false; // Bu dosyayı kalan dosyalardan çıkar
            }
        }
        return true; // Bu dosyayı kalan dosyalarda tut
    });

    // Üçüncü aşama: Kalan dosyaları normal dağıtım algoritmasıyla dağıt
    kalanDosyalar.forEach(dosya => {
        const enUygunHakim = findEnUygunHakim(aktifHakimler, hakimHedefPuanlari, hakimPuanlari, hakimDosyaSayilari, dosya);
        
        if (!enUygunHakim) {
            console.warn(`Dosya dağıtılamadı: ${dosya.id}`);
            return;
        }

        assignDosyaToHakim(enUygunHakim, dosya, hakimPuanlari, hakimDosyalari, hakimDosyaSayilari, settings, false);
    });

    return {
        dagitilmisDosyalar: Array.from(hakimDosyalari.values()).flat(),
        yeniHakimPuanlari: Object.fromEntries(hakimPuanlari),
        hakimHedefPuanlari: Object.fromEntries(hakimHedefPuanlari),
        hakimDosyaSayilari: Object.fromEntries(hakimDosyaSayilari),
        toplamDosyaPuani,
        ortalamaHakimPuani,
        dengesizlikOrani: calculateDengesizlikOrani(hakimPuanlari, hakimHedefPuanlari)
    };
};

const findEnUygunHakim = (hakimler, hedefPuanlar, mevcutPuanlar, dosyaSayilari, dosya) => {
    let enUygunHakim = null;
    let enDusukSkor = Infinity;

    for (const hakim of hakimler) {
        const hedefPuan = hedefPuanlar.get(hakim.id);
        const mevcutPuan = mevcutPuanlar.get(hakim.id);
        const dosyaSayisi = dosyaSayilari.get(hakim.id);
        const tevziOrani = parseAssignmentRatio(hakim.assignmentRatio);

        // Puan faktörü: Mevcut puan ile hedef puan arasındaki fark
        const puanFaktoru = (mevcutPuan / hedefPuan) / tevziOrani;

        // Dosya sayısı faktörü: Hakim'in aldığı dosya sayısı / tevzi oranı
        const dosyaSayisiFaktoru = dosyaSayisi / tevziOrani;

        // Toplam skor: Düşük olan daha iyidir
        const skor = puanFaktoru + dosyaSayisiFaktoru;

        if (skor < enDusukSkor) {
            enUygunHakim = hakim;
            enDusukSkor = skor;
        }
    }

    return enUygunHakim;
};

const findEskiHakimler = (eskiHakimBilgisi, hakimler) => {
    if (!eskiHakimBilgisi) return [];

    const eskiHakimLower = turkishToLower(eskiHakimBilgisi);
    const pattern = hakimler.map(h => escapeRegExp(turkishToLower(h.name))).join('|');
    const regex = new RegExp(pattern, 'g');
    
    const matches = eskiHakimLower.match(regex) || [];
    return hakimler.filter(h => matches.includes(turkishToLower(h.name)));
};

const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

const assignDosyaToHakim = (hakim, dosya, hakimPuanlari, hakimDosyalari, hakimDosyaSayilari, settings, isEskiHakim) => {
    const tevziOrani = parseAssignmentRatio(hakim.assignmentRatio);
    let atanacakPuan;

    if (isEskiHakim) {
        atanacakPuan = dosya.dosyaPuani * settings.hakimKendiDosyasiCarpani;
    } else {
        atanacakPuan = dosya.dosyaPuani;
    }

    hakimPuanlari.set(hakim.id, hakimPuanlari.get(hakim.id) + atanacakPuan);
    hakimDosyalari.get(hakim.id).push({ ...dosya, atananHakim: hakim.name });
    hakimDosyaSayilari.set(hakim.id, hakimDosyaSayilari.get(hakim.id) + 1);
};

const calculateDengesizlikOrani = (mevcutPuanlar, hedefPuanlar) => {
    let toplamSapma = 0;
    let toplamHedefPuan = 0;

    for (const [hakimId, mevcutPuan] of mevcutPuanlar) {
        const hedefPuan = hedefPuanlar.get(hakimId);
        toplamSapma += Math.abs(mevcutPuan - hedefPuan);
        toplamHedefPuan += hedefPuan;
    }

    return toplamSapma / toplamHedefPuan;
};

const parseAssignmentRatio = (ratio) => {
    if (typeof ratio === 'number') return ratio;
    if (typeof ratio === 'string') {
        if (ratio.includes('/')) {
            const [numerator, denominator] = ratio.split('/').map(Number);
            if (denominator !== 0) {
                return numerator / denominator;
            }
        }
        const parsed = parseFloat(ratio);
        if (!isNaN(parsed)) {
            return parsed;
        }
    }
    console.warn(`Geçersiz tevzi oranı: ${ratio}, varsayılan değer 1 kullanılıyor.`);
    return 1;
};

const createEmptyResult = (dosyaSayisi) => ({
    dagitilmisDosyalar: [],
    yeniHakimPuanlari: {},
    hakimHedefPuanlari: {},
    hakimDosyaSayilari: {},
    toplamDosyaPuani: 0,
    ortalamaHakimPuani: 0,
    dengesizlikOrani: 1
});

const findHakimByName = (hakimAdi, hakimler) => {
    if (!hakimAdi) return null;
    return hakimler.find(h => turkishToLower(h.name) === turkishToLower(hakimAdi));
};