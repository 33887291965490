import React from 'react';
import { X } from 'lucide-react';

const FileDetailModal = ({ file, isOpen, onClose }) => {
  if (!isOpen || !file) return null;

  const detailItems = [
    { label: 'Esas No', value: file.esasNo },
    { label: 'Suç Türü', value: file.sucTuru },
    { label: 'Sanık Sayısı', value: file.sanikSayisi },
    { label: 'Mağdur Sayısı', value: file.magdurSayisi },
    { label: 'Klasör Sayısı', value: file.klasorSayisi },
    { label: 'Tebliğname Görüşü', value: file.gorus },
    { label: 'Eski Hakim', value: file.eskiHakim || '-' },
    { label: 'Manuel Tevzi Edilecek Hakim', value: file.manuelTevziEdilecekHakim || '-' },
    { label: 'Dosya Puanı', value: file.dosyaPuani },
    { label: 'Atanan Hakim', value: file.atananHakim || '-' },
  ];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-xl p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-gray-800">Dosya Detayları</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700 transition-colors">
            <X size={24} />
          </button>
        </div>
        <div className="space-y-4">
          {detailItems.map((item, index) => (
            <div key={index} className="flex border-b border-gray-200 pb-3">
              <span className="font-semibold text-gray-700 w-1/3">{item.label}:</span>
              <span className="text-gray-800 w-2/3">{item.value}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FileDetailModal;