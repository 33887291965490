// App.js
import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import { FileManagement } from './components/FileManagement';
import { JudgeManagement } from './components/JudgeManagement';
import { CalculationSettingsPage } from './components/CalculationSettingsPage';
import { JudgeFiles } from './components/JudgeFiles';
import { SummaryTable } from './components/SummaryTable';
import { ColumnSettingsPage } from './components/ColumnSettingsPage';
import DistributionResultsPage from './components/DistributionResultsPage.js';
import SavedDistributions from './components/SavedDistributions.js';
import { FiSettings } from 'react-icons/fi';
import { defaultColumnMappings, defaultCalculationSettings } from './utils/defaultSettings';

const App = () => {
  const [showSettings, setShowSettings] = useState(false);
  const settingsRef = useRef(null);

  useEffect(() => {
    // Kolon ayarlarını yükle veya varsayılanları kullan
    const savedColumnSettings = localStorage.getItem('columnSettings');
    if (!savedColumnSettings) {
      localStorage.setItem('columnSettings', JSON.stringify({ columnMappings: defaultColumnMappings }));
    }

    // Hesaplama ayarlarını yükle veya varsayılanları kullan
    const savedCalculationSettings = localStorage.getItem('calculationSettings');
    if (!savedCalculationSettings) {
      localStorage.setItem('calculationSettings', JSON.stringify(defaultCalculationSettings));
    }

    // Dışarı tıklamayı dinleyen fonksiyon
    const handleClickOutside = (event) => {
      if (settingsRef.current && !settingsRef.current.contains(event.target)) {
        setShowSettings(false);
      }
    };

    // Event listener'ı ekle
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup fonksiyonu
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Router>
      <div className="min-h-screen bg-gray-100">
        <nav className="bg-white shadow-md">
          <div className="max-w-7xl mx-auto px-4">
            <div className="flex justify-between items-center h-16">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <span className="font-bold text-xl">Tevzi Uygulaması</span>
                </div>
                <div className="ml-10 flex items-baseline space-x-4">
                  <Link to="/" className="px-3 py-2 rounded-md text-sm font-medium text-gray-900">
                    Dosya Yönetimi
                  </Link>
                  <Link to="/judges" className="px-3 py-2 rounded-md text-sm font-medium text-gray-500 hover:text-gray-900">
                    Hakim Yönetimi
                  </Link>
                  <Link to="/saved-distributions" className="px-3 py-2 rounded-md text-sm font-medium text-gray-500 hover:text-gray-900">
                  Kayıtlı Tevziler
                  </Link>
                </div>
              </div>
              <div className="relative" ref={settingsRef}>
                <button
                  onClick={() => setShowSettings(!showSettings)}
                  className="flex items-center px-3 py-2 rounded-md text-sm font-medium text-gray-500 hover:text-gray-900"
                >
                  <FiSettings className="h-5 w-5 mr-2" />
                  Ayarlar
                </button>
                {showSettings && (
                  <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <Link to="/settings" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                      Hesaplama Ayarları
                    </Link>
                    <Link to="/column-settings" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                      Sütun Ayarları
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </nav>

        <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <Routes>
          <Route path="/" element={<FileManagement />} />
        <Route path="/hakim-dosyalari" element={<JudgeFiles />} />
        <Route path="/ozet-tablo" element={<SummaryTable />} />
            <Route path="/judges" element={<JudgeManagement />} />
            <Route path="/settings" element={<CalculationSettingsPage />} />
            <Route path="/column-settings" element={<ColumnSettingsPage />} />
            <Route path="/distribution-results" element={<DistributionResultsPage />} />
            <Route path="/saved-distributions" element={<SavedDistributions />} />
            <Route path="/file-management" element={<FileManagement />} /> 
          </Routes>
        </main>
      </div>
    </Router>
  );
};

export default App;